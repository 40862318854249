import React, { useState, useEffect } from 'react';
import "../products/products.css";
import { Container } from 'react-bootstrap';
import DataTable, { useDataTableHandle } from '../DataTable';
import { postCommodityCodesLazyLoad } from '../apiClass_commoddity_codes';
import { ExternalLinkAlt } from "@styled-icons/fa-solid";
import { useNavigate } from "react-router-dom";

const CommodityCodeList = () => {
  const [commodityCodes, setCommodityCodes] = useState([]);
  const [requestFailed, setRequestFailed] = useState(false);
  const [paginationLoaded, setPaginationLoaded] = useState(false);
  const [dashboard, setDashboard] = useState(null);
  
  const navigate = useNavigate();
  const dth = useDataTableHandle();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setDashboard(urlParams.get('dashboard') || "");
  }, []);

  useEffect(() => {
    if (paginationLoaded && dashboard !== null) {
      fetchData();
    }
  }, [paginationLoaded, dashboard]);

  const fetchData = async () => {
    try {
      const data = await postCommodityCodesLazyLoad({
        skip: dth.current.pagination.pageNo * dth.current.pagination.itemsPerPage,
        amount: dth.current.pagination.itemsPerPage,
        sort_by: dth.current.sortStatus.currentKey,
        sort_order: dth.current.sortStatus.direction,
        filter: dth.current.filterStatus?.filterText ? { [dth.current.filterStatus.filterByKey] : dth.current.filterStatus.filterText } : {},
        dashboardElement: dashboard
      });
      dth.current.setServerDataLength(data.totalItems);
      setCommodityCodes(data.data);
    } catch (err) {
      console.error(err);
      setRequestFailed(true);
    }
  };

  const updateData = async (setPaginationLength, pagination, sortStatus, search, filter) => {
    try {
      //read the parameter from the url and assign it to the dashboardFilterParam
      const urlParams = new URLSearchParams(window.location.search);
      const dashboardFilterParam = urlParams.get('dashboard');

      const data = await postCommodityCodesLazyLoad({
        skip: pagination.pageNo * pagination.itemsPerPage,
        amount: pagination.itemsPerPage,
        sort_by: sortStatus.currentKey,
        sort_order: sortStatus.direction,
        search,
        filter,
        dashboardElement: dashboardFilterParam
      });
      setPaginationLength(data.totalItems);
      setCommodityCodes(data.data);
    } catch (err) {
      console.error(err);
      setRequestFailed(true);
    }
  };

  const handleReset = () => {
    setDashboard("");
  };

  if (requestFailed) return <p>Failed!</p>;

  return (
    <Container className="container-md">
      <section className='floating-box' style={{ maxWidth: '1600px' }}>
        <div className="floating-box-header-general">
          <span>Commodity Codes</span>
        </div>
        <div className="px-3 pt-4">
          <DataTable
            showAdvnSearch={true}
            data={commodityCodes}
            handle={dth}
            className="table-striped"
            filterable
            sortable
            lazyLoading
            advancedSearch
            onUpdate={updateData}
            onPaginationLoaded={() => setPaginationLoaded(true)}
            handleReset={handleReset}
          >
            <DataTable.Headers style={{ fontSize: "14.4px", paddingBottom: '10px', borderBottom: '1px solid #ddd', backgroundColor: '#fff', borderTop: '1px solid #ddd' }} />
            <DataTable.Pagination itemsPerPage={[10, 25, 50]} areaPadding={'40px 0 10px 0'} />
            <DataTable.Column colKey="customer" header="Customer Name" searchOpts={{ type: "text", sendAs: "customer" }} />
            <DataTable.Column colKey="eori" header="EORI" searchOpts={{ type: "text", sendAs: "eori" }} />
            <DataTable.Column colKey="hsCode" header="HS Code" searchOpts={{ type: "text", sendAs: "hsCode" }} />
            <DataTable.Column colKey="description" header="Description" searchOpts={{ type: "text", sendAs: "description" }} />
            <DataTable.Column colKey="commodityCode" header="Map to GB HS Code" searchOpts={{ type: "text", sendAs: "commodityCode" }} />
            <DataTable.Column colKey="disabled" header="Disabled" searchOpts={{ type: "text", sendAs: "disabled" }} style={{ textTransform: 'uppercase' }}>
              {item => item['disabled']?.toString()}
            </DataTable.Column>
            <DataTable.Column header="Edit" style={{ height: '1px', padding: 0 }} headerStyle={{ textAlign: 'center' }}>
              {({ id }) => (
                <button className="external-table-link" onClick={() => navigate(`/CommodityCodeDetails/${id}`)}>
                  <ExternalLinkAlt width="1.1rem" height="1.1rem" color="#00857d" />
                </button>
              )}
            </DataTable.Column>
          </DataTable>
        </div>
        <span className="bottomAttempt"></span>
      </section>
    </Container>
  );
};

export default CommodityCodeList;