import React, { useState, useEffect } from "react";
import { Container, Modal } from "react-bootstrap";
import { Input } from "../../Input/Input.js";
import { getCM8Details, getNewLoadQaComplianceChecklist } from "../../apiCalls.js";
import BusinessPartnerCard from "../../BusinessPartners/BusinessPartnerCard.js";

const ComplianceChecklist = ({ companyId, companyName, consigneeData, revenuePayableData }) => {
    const [showInvoiceModal, setShowInvoiceModal] = useState(false);
    const [showBPModal, setShowBPModal] = useState(false);
    const [invoiceModalData, setInvoiceModalData] = useState({
        billableCustomer: '', 
        billableCustomerValid: true, 
        cabieStatus: '', 
        cabieStatusValid: true, 
        cm8Approved: '',  
        cm8ApprovedValid: true,
        companyName: '', 
        dbStatus: '', 
        dbStatusValid: true,
        eori: '',  
        id: null
    });
    const [consigneeStatus, setConsigneeStatus] = useState({
        cabieStatus: '', 
        cabieStatusValid: true, 
        consignee: '', 
        dbStatus: '', 
        dbStatusValid: true, 
        eori: '', 
        id: null, 
        standingAuthority: '',
        standingAuthorityValid: true
    });
    const [modalData, setModalData] = useState({
        billableCustomer: '', 
        billableCustomerValid: true, 
        cabieStatus: '', 
        cabieStatusValid: true, 
        cm8Approved: '',  
        cm8ApprovedValid: true,
        companyName: '', 
        dbStatus: '', 
        dbStatusValid: true,
        eori:'', 
        consignee: '', 
        id: null, 
        isBP: false, 
        standingAuthority: '',
        standingAuthorityValid: true, 
        title: '', 
        origin: ''
    });

    const fieldStatus_Error = 'invalidHighlight';

    //when invoiceModalData or consigneeStatus is updated, we check if the modal is open and if the modal is for Invoice or Consignee, then we update the modal data.
    useEffect(() => {
        if(showInvoiceModal){
            if(modalData.origin === 'Invoice'){
                updateModalData(invoiceModalData, modalData.title, modalData.origin, modalData.isBP);
            }
            else if(modalData.origin === 'Consignee'){
                updateModalData(consigneeStatus, modalData.title, modalData.origin, modalData.isBP);
            }
        }
    }, [invoiceModalData, consigneeStatus]);

    //run this if showBPModal changes. Then we check if the modal is closing, and if it is, we update the data.
    useEffect(() => {
        const fetchData = async () => {
            if (!showBPModal) {
                if(modalData.origin === 'Invoice') await fetchCompanyDetails();
                else if(modalData.origin === 'Consignee') await fetchConsigneeDetails();
        
                //update the data for 'Revenue Payable' component when consignee is update
                revenuePayableData();
            }
        };
    
        fetchData();
    }, [showBPModal]);

    useEffect(() => {
        modalData.title && setShowInvoiceModal(true);
    }, [modalData]);

    useEffect(() => {
        companyId && fetchCompanyDetails();
    }, [companyId]);

    useEffect(() => {
        if (consigneeData.eori) {
            fetchConsigneeDetails();
        }

        //update the data for 'Revenue Payable' component when consignee is update
        revenuePayableData();
    }, [consigneeData]);

    const fetchCompanyDetails = async () => {
        try {
            const data = await getCM8Details(companyId);
            setInvoiceModalData({
                billableCustomer: data.billableCustomer,  
                billableCustomerValid: data.billableCustomerValid, 
                cabieStatus: data.cabieStatus, 
                cabieStatusValid: data.cabieStatusValid, 
                cm8Approved: data.cm8Approved, 
                cm8ApprovedValid: data.cm8ApprovedValid, 
                companyName: companyName, //for invoice, companyName is passed as prop, so no need to populate from API 
                dbStatus: data.dbStatus, 
                dbStatusValid: data.dbStatusValid, 
                eori: data.eori, 
                id: data.id
            });
        } catch (error) {
            console.log('Unable to fetch data from getCM8Details for companyId: ', companyId);
            setInvoiceModalData({ 
                billableCustomer: '', 
                billableCustomerValid: true, 
                cabieStatus: '', 
                cabieStatusValid: false, //if unable to fetch data, cabieStatusValid is false
                cm8Approved: '',  
                cm8ApprovedValid: true,
                companyName: companyName, //for invoice, companyName is passed as prop, so no need to populate from API 
                dbStatus: '', 
                dbStatusValid: false, //if unable to fetch data, dbStatusValid is false
                eori: '',  
                id: null
            });
        }
    };

    const fetchConsigneeDetails = async () => {
        try {
            const data = await getNewLoadQaComplianceChecklist(consigneeData.id);
            setConsigneeStatus({ 
                cabieStatus: data.cabieStatus, 
                cabieStatusValid: data.cabieStatusValid, 
                companyName: consigneeData.companyName, //for consignee, companyName is passed as prop inside the consigneeData object, so no need to populate from API 
                dbStatus: data.dbStatus, 
                dbStatusValid: data.dbStatusValid, 
                eori: consigneeData.eori, 
                id: data.id,  
                standingAuthority: data.standingAuthority,
                standingAuthorityValid: data.standingAuthorityValid
             });
        } catch (error) {
            console.log('Unable to fetch data from getNewLoadQaComplianceChecklist for consigneeData.id: ', consigneeData.id);
            setConsigneeStatus({ 
                cabieStatus: '', 
                cabieStatusValid: false, //if unable to fetch data, cabieStatusValid is false 
                consignee: '',
                companyName: consigneeData.companyName, //for consignee, companyName is passed as prop inside the consigneeData object, so no need to populate from API
                dbStatus: '', 
                dbStatusValid: false, //if unable to fetch data, dbStatusValid is false
                eori: consigneeData.eori, 
                id: null, 
                standingAuthority: '',
                standingAuthorityValid: true
            });
        }
    }

    const updateModalData = (data, modalTitle, modalOrigin, isBP) => {
        setModalData({
            billableCustomer: data.billableCustomer, 
            billableCustomerValid: data.billableCustomerValid, 
            cabieStatus: data.cabieStatus, 
            cabieStatusValid: data.cabieStatusValid, 
            cm8Approved: data.cm8Approved,  
            cm8ApprovedValid: data.cm8ApprovedValid,
            companyName: data.companyName, 
            dbStatus: data.dbStatus, 
            dbStatusValid: data.dbStatusValid,
            eori: data.eori, 
            consignee: data.consignee, 
            id: data.id, 
            isBP: isBP, 
            standingAuthority: data.standingAuthority,
            standingAuthorityValid: data.standingAuthorityValid, 
            title: modalTitle, 
            origin: modalOrigin
        });
    }

    const handleKebabClick = async (origin) => { 
        let data = '';
        let modalTitle = '';
        let modalOrigin = '';
        let isBP = false;

        switch (origin) {
            case 'invoice':
                data = invoiceModalData;
                modalTitle = 'Customer Validation';
                modalOrigin = 'Invoice';
                isBP = true; //invoice will always have a BP, so isBP is true
                break;
            case 'consignee':
                data = consigneeStatus;
                modalTitle = 'Consignee Validation';
                modalOrigin = 'Consignee';
                isBP = consigneeStatus.id ? true : false; //if consignee has id, then isBP is true. Otherwise, isBP is false, since a Consignee can be a BP or not
                break;
            default:
        }

        updateModalData(data, modalTitle, modalOrigin, isBP);
    }

    const BPModal = () => {
        return (
            <Modal animation={false} show={showBPModal} onHide={() => setShowBPModal(false)} size="xl" centered>
                <BusinessPartnerCard back={
                    handleBackButtonClick
                } id={modalData.id} />
            </Modal>
        )
    }

    const handleViewBPModal = () => {
        setShowBPModal(true);
        setShowInvoiceModal(false);
    }

    const handleBackButtonClick = () => {
        setShowInvoiceModal(true);
        setShowBPModal(false);
    }

    const detailsModal = () => {
        return (
            <Modal className="qa-checklist-modal" animation={false} show={showInvoiceModal} onHide={() => setShowInvoiceModal(false)} size="md" centered>
                <Modal.Header>
                    <Modal.Title>{modalData.title}</Modal.Title>
                    <button
                        type="button"
                        class="btn-close"
                        aria-label="Close"
                        onClick={() => setShowInvoiceModal(false)}
                    />
                </Modal.Header>
                <Modal.Body>
                    <div className="bp-table-grid bp-grid-default" style={{ display: 'grid', gridTemplateColumns: '45% 55%', padding: '0' }}>
                        <div className={ `bp-table-grid-header bp-grid-default` }> {modalData.origin === 'Consignee' ? ('Consignee') : ('Invoice Customer') }</div>
                        <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}> {modalData.origin === 'Invoice' ? companyName : modalData.companyName} </div>
                        {
                            modalData.origin === 'Consignee' && (
                                <>
                                    <div className={ `bp-table-grid-header bp-grid-default` }>EORI Number</div>
                                    <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>{modalData.eori}</div>
                                </>
                            )
                        }
                        <div className={ `bp-table-grid-header bp-grid-default ${ !modalData.cabieStatusValid ? fieldStatus_Error : '' }` }
                        >CABIE Status</div>
                        <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>{modalData.cabieStatus}</div>
                        <div className={ `bp-table-grid-header bp-grid-default ${ !modalData.dbStatusValid ? fieldStatus_Error : '' }` }
                        >DB Status</div>
                        <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>{modalData.dbStatus}</div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="d-flex w-100 justify-content-between">
                        <button className="epo-continue-button" style={{ fontSize: "1rem", width: "auto", padding: "0.25rem 1.45rem" }} type="button" 
                            onClick={() => setShowInvoiceModal(false)}
                        >Cancel</button>
                        <button className="blue-button-newload" style={{ fontSize: "1rem", width: "auto", padding: "0.25rem 1.45rem" }} type="button"
                            onClick={handleViewBPModal} disabled={!modalData.isBP}
                        >View</button>
                    </div>
                </Modal.Footer>
            </Modal>
        )
    }

    const isInvoiceCustomerValid = invoiceModalData.cabieStatusValid && invoiceModalData.dbStatusValid;
    const isConsigneeValid = consigneeStatus.cabieStatusValid && consigneeStatus.dbStatusValid;

    return (
        <Container fluid>
            {detailsModal()}
            {BPModal()}
            <div className="bp-section-heading">Compliance Checklist</div>
            <div className="form-field invoice-customer">
                <Input
                    label="Invoice Customer"
                    name="invoiceCustomer"
                    type="text"
                    value={(companyName) ? companyName : `-`}
                    required
                    disabled={true}
                    optionsButton={true}
                    optionsButtonCallback={() => handleKebabClick('invoice')}
                    className={ !isInvoiceCustomerValid ? 'error' : '' }
                />
            </div>
            <div className="form-field invoice-customer">
                <Input
                    label="Consignee"
                    name="consigneeName"
                    type="text"
                    value={consigneeData.companyName}
                    required
                    disabled={true}
                    optionsButton={true}
                    optionsButtonCallback={() => handleKebabClick('consignee')}
                    className={ !isConsigneeValid ? 'error' : ''}
                />
            </div>
            <div className="bp-table-grid bp-grid-default" style={{ display: 'grid', gridTemplateColumns: '45% 55%', padding: '0' }}>
                <div className={ `bp-table-grid-header bp-grid-default ${ !consigneeStatus.standingAuthorityValid ? fieldStatus_Error : '' }` }>Standing Authority</div>
                <div className="bp-table-grid-cell bp-grid-default" style={{ padding: '5px' }}>{consigneeStatus.standingAuthority}</div>
            </div>
        </Container>
    );
};

export default ComplianceChecklist;