import React, { useState, useEffect, useContext } from "react";
import { Container } from "react-bootstrap";
import ComplianceChecklist from "./ComplianceChecklist";
import DataCheck from "./DataCheck";
import RevenuePayable from "./RevenuePayable";
import ItemChecklist from "./ItemChecklist";
import { StoreContext } from "../../Store";
import LoadHeader from '../../LoadList/LoadHeader';
import { getNeeNorDetails, getNewLoadQaVat, getNewLoadDetails, getCheckModalDropdownValues, postCheckPreferentialValidation } from "../../apiCalls";
import "../../BusinessPartners/BusinessPartnerCard.css";
import "./QAChecklistTab.css";
import CheckModal from "./CheckModal";

const QAChecklistTab = (props) => {
    const [consigneeData, setConsigneeData] = useState({});
    const [showCheckModal, setShowCheckModal] = useState(false);
    const [invoiceCurrency, setInvoiceCurrency] = useState('GBP');
    const store = useContext(StoreContext);
    const consigneeID = store.consignee[0];

    const [newLoadQaVat, setNewLoadQaVat] = useState({
        id: null,
        vatMessage: '',
        vatValid: true
    });

    const [nonHybridPortModelID, setNonHybridPortModelID] = useState(0);
    const [productList, setProductList] = useState([]);
    const [totalValues, setTotalValues] = useState({
        totalPackageCount: 0,
        totalGrossMass: 0,
        totalNetMass: 0,
        totalValue: 0,
        totalStatValue: 0,
        totalDuty: 0,
        totalVAT: 0
    });
    const [checkModalData, setCheckModalData] = useState({
        euCountries: [],
        thirdCountries: []
    });
    const [validPreferenceOptions, setValidPreferenceOptions] = useState([]);
    const [statementMadeByOptions, setStatementMadeByOptions] = useState([]);
    const [isCheckValid, setIsCheckValid] = useState(false);


    useEffect(() => {
        const fetchConsigneeData = async () => {
            try {
                const data = await getNeeNorDetails(consigneeID);
                setConsigneeData(data[0]);
            } catch (error) {
                console.error('Error fetching consignee data from getNeeNorDetails for consignee:  ', consigneeID, ' | ', error);
            }
        };
        consigneeID && fetchConsigneeData();
    }, [consigneeID]);

    const revenuePayableData = async () => {
        try {
            const data = await getNewLoadQaVat(consigneeID);
            setNewLoadQaVat({
                id: data[0].id,
                vatMessage: data[0].vatMessage,
                vatValid: data[0].vatValid
            });
        } catch (error) {
            console.error('Error fetching consignee data from getNewLoadQaVat for consignee:  ', consigneeID, ' | ', error);
        }
    }

    //this useEffect will run every time store.validatedRowsReadyToSubmit[0] changes
    useEffect(() => {
        const fetchModalData = async (dropdown) => {
            const response = await getCheckModalDropdownValues(dropdown);
            switch (dropdown) {
                case "VPS": 
                    setValidPreferenceOptions(response)
                  break;
                case "SMOB":
                    setStatementMadeByOptions(response)
                break;
              
              }
        }

        const fetchCheckModalValidation = async(data) => {
            const response = await postCheckPreferentialValidation(JSON.stringify(data));
            const result = {
                euCountries: response
                    .filter(country => country.pref && country.eu) // Filter where 'pref' is true and 'eu' is true
                    .map(country => country.origin),              // Extract 'origin'
                thirdCountries: response
                    .filter(country => country.pref && country.thirdCountry) // Filter where 'pref' is true and 'thirdCountry' is true
                    .map(country => country.origin)                          // Extract 'origin'
            };


            setIsCheckValid(result.euCountries.length === 0 && result.thirdCountries.length === 0);

            setCheckModalData(result);
        }

        let totalPackageCount = 0;
        let totalGrossMass = 0;
        let totalNetMass = 0;
        let totalValue = 0;

        const updatedProductList = store.validatedRowsReadyToSubmit[0].map((product, index) => {
            totalPackageCount += parseInt(product.packageCount);
            totalGrossMass += parseFloat(product.grossMass);
            totalNetMass += parseFloat(product.netMass);
            totalValue += parseFloat(product.itemValue);


            return {
                'Item': index + 1,
                'Valid': true,
                'HS Code': product.commoditycode,
                'Origin': product.origin,
                'Packages': product.packageCount, 
                'Gross (Kg)': parseFloat(product.grossMass)?.toFixed(2),
                'Net (Kg)': parseFloat(product.netMass)?.toFixed(2),
                'Value': parseFloat(product.itemValue)?.toFixed(2),
                'Preference': product.preferential,
                'Duty Liability': '-', 
                'VAT Liability': '-'
            }
        });

        setProductList(updatedProductList);
        setTotalValues(prevValues => ({
            ...prevValues,
            totalPackageCount,
            totalGrossMass,
            totalNetMass,
            totalValue
        }));

        // get modal data, passing 'VPS' as the dropdown value
        fetchModalData('VPS');
        fetchModalData('SMOB');

        const productPreferences = updatedProductList.map(product => { 
            return {
                origin: product.Origin,
                preferential: product.Preference.toLowerCase() == 'yes' ? true : false
            }
        });

        store.invoicecurr[0] && setInvoiceCurrency(store.currencyOptions[0].find(currency => currency.id === store.invoicecurr[0].toString()).data);
        fetchCheckModalValidation(productPreferences)

    }, [store.validatedRowsReadyToSubmit[0]]);

    const status = true ? 'green-tick' : 'red-cross';
    const totalValueLabel = `Total Value ${store.invoicecurr[0] ? `(${store.invoicecurr[0]})` : ''}`;

    const loadIsReady = store.GBimport[0] || store.GBexport[0]; //load is not ready if GBimport and GBexport is set to FALSE
    
    const isLoadAnImport = store.GBimport[0];
    const portRowLabel = isLoadAnImport ? 'Entry Port' : 'Exit Port';

    const portID = isLoadAnImport ? store.entry[0] : store.exit[0];
    const portOptions = isLoadAnImport ? store.entryPortOptions[0] : store.exitPortOptions[0];

    const getPortType = (options, id) =>{  
        const selectedOption = options?.find(option => parseInt(option.id) === parseInt(id));
        return selectedOption ? parseInt(selectedOption?.hybrid) : null;
    }

    useEffect(() => {
        const fetchPortModelFromPortID = async () => {
            try {
                const data = await getNewLoadDetails('portprocess', portID);
                setNonHybridPortModelID(parseInt(data[0]?.portProcessId));
            } catch (error) {
                console.error('Error fetching port models data from getNewLoadDetails for portID:  ', portID, ' | ', error);
            }
        };
        (portID && loadIsReady) && fetchPortModelFromPortID();
    }, [loadIsReady, isLoadAnImport, portID]);

    const portModelID = getPortType(portOptions, portID) ? store.portsModal[0] : nonHybridPortModelID;

    const handleShowCheckModal = async (v, checkValid) => {
        checkValid !== undefined && setIsCheckValid(checkValid);
        setShowCheckModal(v);
    }

    return (
        <div style={{ display: 'initial' }}>
            <CheckModal showModal={showCheckModal}
            setShowModal={handleShowCheckModal}
            checkModalData={checkModalData}
            totalValue={totalValues.totalValue}
            invoiceCurrency={invoiceCurrency}
            validPreferenceOptions={validPreferenceOptions}
            statementMadeByOptions={statementMadeByOptions}
            />
            <Container fluid style={{ borderRadius: "20px", backgroundColor: "#f0f4f9" }}>
                <div className='d-flex flex-row justify-content-between align-items-start px-2'>
                    <div className={ `floating-box-header-newload flex-row ${status}` }
                        style={{ paddingTop: '18px 0 0 0', marginLeft: '0', height: 'auto' }}>
                        Quality Checklist
                    </div>
                    <div className="d-flex flex-column gap-2 justify-content-between align-items-end py-3">
                        <LoadHeader
                            //portalRef={`Value taken from /newload/save reseponse - transferid`}
                            jobRef={store.jobRef[0]}
                        />
                    </div>
                </div>
                <div className="business-partner-container qa-checklist">
                    <div class='main-checklist'>
                        <ComplianceChecklist 
                            companyId={store.clientID[0]}
                            companyName={store.clientIDname[0]}
                            consigneeData={consigneeData}
                            revenuePayableData={revenuePayableData}
                        />
                        <DataCheck
                            selectedRouteID={store.directionSelected[0]} 
                            availableRoutes={store.movementOptions[0]} 
                            loadingDate={store.date[0]}
                            portRowLabel={portRowLabel}
                            portID={portID} 
                            portOptions={portOptions} 
                            portModelID={portModelID} 
                            portsModalOptions={store.portsModalOptions[0]} 
                            invoiceCurrencyID={store.invoicecurr[0]} 
                            currencyOptions={store.currencyOptions[0]} 
                            incotermID={store.selectincoterm[0]} 
                            incotermsOptions={store.incotermsOptions[0]} 
                            adjustments={store.adjustments[0]} 
                            controlledGoodsType={store.controlledGoods[0]}
                            setShowCheckModal={handleShowCheckModal} 
                            isLoadAnImport={isLoadAnImport}
                            totalValue={totalValues.totalValue}
                            preferenceCheck={isCheckValid}
                            displayCheckModal={checkModalData.euCountries.length > 0 || checkModalData.thirdCountries.length > 0}
                        />
                        <RevenuePayable
                            consigneeID={store.consignee[0]}
                            newLoadQaVat={newLoadQaVat}
                        />
                    </div>
                    <div class='items-checklist position-relative'>
                        <ItemChecklist 
                            totalPackageCountLabel='Item Rows' 
                            totalPackageCount={totalValues.totalPackageCount}
                            totalGrossMassLabel='Gross (Kg)' 
                            totalGrossMass={totalValues.totalGrossMass}  
                            totalNetMassLabel='Net (Kg)' 
                            totalNetMass={totalValues.totalNetMass} 
                            totalValueLabel={totalValueLabel}
                            totalValue={totalValues.totalValue}
                            totalStatValueLabel='Stat Value (GBP)'
                            totalStatValue={totalValues.totalStatValue} 
                            totalDutyLabel='Total Duty (GBP)'
                            totalDuty={totalValues.totalDuty}
                            totalVATLabel='Total VAT (GBP)'
                            totalVAT={totalValues.totalVAT} 
                            productList={productList} 
                            prevPage={props.prevPage}
                            newLoadAction={props.newLoadAction}
                        />
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default QAChecklistTab;