/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "../table.css";
import "../report.css";
import "./commoditycodes.css";
import {
  updateCommodityCode,
  getCommodityCodeDetails
} from "../apiClass_commoddity_codes";
import { Input } from "../Input/Input.js";
import { ShowIf } from '../Security/security.js';
import isoDateConverter from '../isoDateConverter';
import { useParams, useNavigate } from "react-router-dom";

const CommodityCodeDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [failedUpdate, setFailedUpdate] = useState(false);
  const [commodityCodeMapUpdated, setCommodityCodeMapUpdated] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [commodityCodeDetails, setCommodityCodeDetails] = useState({
    id: null,
    eori: '',
    customer: '',
    hsCode: 0,
    description: '',
    commodityCode: 0,
    disabled: false,
    createdBy: '',
    createdDate: '',
    updatedBy: '',
    updatedDate: '',
  });

  const returnToCommodityCodesListPage = () => {
    navigate('/CommodityCodes');
  }

  const updateCommodityCodeDetails = (key, value) => {
    setCommodityCodeDetails(prev => ({
      ...prev,
      [key]: value
    }));
    setCommodityCodeMapUpdated(true);
  };

  const validateCommodityCodeChange = () => {
    setFailedUpdate(false);
  }

  const fetchData = async () => {
    try {
      const data = await getCommodityCodeDetails(id);
      setCommodityCodeDetails(data[0]);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const handleUpdate = async () => {
    try {
      const payload = {
        id: commodityCodeDetails.id,
        commodityCode: commodityCodeDetails.commodityCode,
        user: sessionStorage.getItem('fullName'),
        disabled: commodityCodeDetails.disabled
      }

      const response = await updateCommodityCode(payload);
      const status = response[0]?.completed?.toLowerCase();
      const errormessage = response[0]?.errormessage;

      if (status === 'updated') {
        returnToCommodityCodesListPage();
      } else {
        setFailedUpdate(true);
        setResponseMessage(errormessage);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const displayDetailLabelWithValue= (label, value) => {
    return (
      <p className="mapping-info">
        <span className="label">{label}</span>
        <span className="value">{value}</span>
      </p>
    );
  };

  return (
    <div className="full-width-container">
      <section className="floating-box commodity-code-details" style={{ maxWidth: "700px" }}>
        <div className="floating-box-header">
          <span>Edit Commodity Code Mapping</span>
        </div>
        <div className="manage-commodity-codes">
          <input type="hidden" name="id" value={commodityCodeDetails.id} />
          <div className='input-outer-container mb-0 mapping-info-group'>
            {displayDetailLabelWithValue('Customer Name', commodityCodeDetails.customer)}
            {displayDetailLabelWithValue('EORI', commodityCodeDetails.eori)}
            {displayDetailLabelWithValue('HS Code', commodityCodeDetails.hsCode)}
            {displayDetailLabelWithValue('Description', commodityCodeDetails.description)}
          </div>
          <Input
            type="number"
            name="commodityCode"
            label="Map to GB HS Code"
            value={commodityCodeDetails.commodityCode}
            required
            onChange={(e) => updateCommodityCodeDetails('commodityCode', e.target.value)}
            onBlur={validateCommodityCodeChange}
          />
          <div className='input-outer-container mt-3'>
            <label htmlFor="disabled" className="label-text">Disabled</label>
            <div className='input-inner-container'>
              <input type="checkbox" name="disabled" className="neenor-checkbox"
                checked={commodityCodeDetails.disabled}
                onChange={(e) => updateCommodityCodeDetails('disabled', e.target.checked)}
              />
            </div>
          </div>
          <div className='input-outer-container mt-3 mb-0 pt-3 border-top mapping-info-group'>
            {displayDetailLabelWithValue('Created By', commodityCodeDetails.createdBy)}
            {displayDetailLabelWithValue('Created Date', commodityCodeDetails.createdDate ? isoDateConverter(commodityCodeDetails.createdDate) : '-')}
            {displayDetailLabelWithValue('Last Updated By', commodityCodeDetails.updatedBy)}
            {displayDetailLabelWithValue('Last Updated Date', commodityCodeDetails.updatedDate ? isoDateConverter(commodityCodeDetails.updatedDate) : '-')}
          </div>
          <section className="action-buttons border-top py-3 gap-3">
            <button type="button" className="stepper-button red" onClick={returnToCommodityCodesListPage}>
              Back
            </button>
            <ShowIf or={[{ department: 'OPS' }, { department: 'DCT' }, { department: 'QA' }]}>
              <span>
                { 
                  failedUpdate && <p className="error-message">{responseMessage}</p>
                }
                <button type='submit' disabled={failedUpdate || !commodityCodeMapUpdated} className="stepper-button" onClick={handleUpdate}>
                  Save
                </button>
              </span>
            </ShowIf>
          </section>
        </div>
      </section>
    </div>
  );
};

export default CommodityCodeDetails;