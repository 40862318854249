/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import "./loaddetails.css";
import "../Newload/newLoad.css";
import { Container, Tab } from 'react-bootstrap';
import LoadDetails from "./LoadDetails.js";
import LoadItems from "./LoadItems.js";
import LoadStatus from "./LoadStatus.js";
import { getReportDetails, getNewLoad } from '../apiCalls';
import { StoreContext } from "../Store";
import _ from 'lodash'

const Loads = (props) => {
  const store = useContext(StoreContext);
  const [currentTab, setCurrentTab] = useState(0);

  const [headerData, setHeaderData] = useState({});
  const [adjustments, setAdjustments] = useState([]);

  const { id } = useParams();

  useEffect(() => {
    async function fetchData() {
      getNewLoad("currency").then((data) => { store.currencyOptions[1](data); });
      getNewLoad("vat").then((data) => {store.VatOptions[1](data)});
      getNewLoad("pref").then((data) => {store.PreferentialOptions[1](data)})
      const data = await getReportDetails('details', id);
      setHeaderData(data[0]);
    }
    fetchData();
    
  }, [])

  useEffect(() => {
    if (store.currencyOptions[0]?.length > 0 && !_.isEmpty(headerData)) {
      setAdjustments([
        {name: "freightCharges",
        display: "Freight Charges",
        amount: headerData.freightCharges,
        currency: store.currencyOptions[0].find((opt) => parseInt(opt.id) === parseInt(headerData.freightChargescurrency))?.data,
        required: false},
        {name: "insuranceCharges",
        display: "Insurance Charges",
        amount: headerData.insuranceCharges,
        currency: store.currencyOptions[0].find((opt) => parseInt(opt.id) === parseInt(headerData.insuranceChargescurrency))?.data,
        required: false},
        {name: "vatAdjustment",
        display: "VAT Adjustment",
        amount: headerData.vatAdjustment,
        currency: store.currencyOptions[0].find((opt) => parseInt(opt.id) === parseInt(headerData.vatAdjustmentcurrency))?.data,
        required: false},
        {name: "otherCharges",
        display: "Other Additions",
        amount: headerData.otherCharges,
        currency: store.currencyOptions[0].find((opt) => parseInt(opt.id) === parseInt(headerData.otherChargescurrency))?.data,
        required: false},
        {name: "invoiceDiscount",
        display: "Other Deductions",
        amount: headerData.invoiceDiscount,
        currency: store.currencyOptions[0].find((opt) => parseInt(opt.id) === parseInt(headerData.invoiceDiscountcurrency))?.data,
        required: false}
    ])
    }
  }, [store.currencyOptions[0], headerData])

  return (
    <Container fluid id="breadcrumb-tabber" className="pt-4 mt-3">
      <div className="floating-back-button d-none d-lg-block">
        {currentTab > 0 &&
          (<button className="blue-button-newload" style={{ borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', borderTopRightRadius: '0.75rem', borderBottomRightRadius: '0.75rem', padding: '0.25rem', width: '2.5rem' }} onClick={() => { setCurrentTab(prev => prev - 1) }}><LeftChevron /></button>)
        }
      </div>
      <Container fluid className="semi-fluid-container">
        <Tab.Container activeKey={currentTab}>
          <div className="semi-fluid-container mb-4">
            <div className="tab-header loadlist">
              <button className={`tab-control text-eoriblue ${currentTab > 0 ? 'done' : (currentTab === 0 ? 'active' : 'neutral')
                }`} onClick={() => (setCurrentTab(0))}>
                < NumberedCircle i={0} />
                <span>Header</span>
              </button>
              <div className={'horizontal-line flex-grow-1 rounded-pill breadcrumb-line'} />
              <button className={`tab-control text-eoriblue ${currentTab > 1 ? 'done' : (currentTab === 1 ? 'active' : 'neutral')
                }`} onClick={() => (setCurrentTab(1))}>
                <NumberedCircle i={1} />
                <span>Items</span>
              </button>
              <div className={'horizontal-line flex-grow-1 rounded-pill breadcrumb-line'} />
              <button className={`tab-control text-eoriblue ${currentTab > 2 ? 'done' : (currentTab === 2 ? 'active' : 'neutral')
                }`} onClick={() => (setCurrentTab(2))}>
                <NumberedCircle i={2} />
                <span>Status</span>
              </button>
            </div>
          </div>

          <Tab.Content>
            <Tab.Pane eventKey={0}>
              <div className="semi-fluid-container">
                <LoadDetails adjustments={adjustments} headerData={headerData} nextPage={() => setCurrentTab(1)}/>
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey={1}>
              <div className="semi-fluid-container">
                 <LoadItems headerData={headerData} invoicenumber={id} portalRef={headerData?.portalRef} nextPage={() => setCurrentTab(2)} prevPage={() => { setCurrentTab(prev => prev - 1) }}/>
              </div>
              <div className="w-100 d-flex justify-content-end mt-3">
              </div>
            </Tab.Pane>

            <Tab.Pane eventKey={2}>
              
            <div className="semi-fluid-container">
              <LoadStatus headerData={headerData} invoicenumber={id} portalRef={headerData?.portalRef} prevPage={() => { setCurrentTab(prev => prev - 1) }}/>
              </div>
                 <div className="w-100 d-flex justify-content-end mt-3">
              </div>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      </Container>
    </Container >
  )
}


const LeftChevron = () => (
  <svg xmlns="http://www.w3.org/2000/svg" className="icon-lg" viewBox="0 0 20 20" fill="currentColor">
    <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
  </svg>
)

const NumberedCircle = ({ i, bg, fg }) => (
  <span className={`icon-md ${bg} ${fg} rounded-circle`}>{i + 1}</span>
)

export default Loads;
